import { connect } from "react-redux";
import diagnosticReducer from "./diagnostic.reducers";
import diagnosticActions from "./diagnostic.actions";
import layoutActions from '../layout/layout.actions';
import checkoutActions from '../checkout/checkout.actions';
import profileActions from "../profile/profile.actions";
import authActions from "../../auth/auth.actions";
import { hsLocAction } from "../util-components/hs-location/hs-location.redux";
import dashboardActions from "../dashboard/dashboard.actions";

const diagnosticConnect = (Component) => {
    return connect(diagnosticReducer, {
        ...authActions, ...diagnosticActions, ...hsLocAction,...dashboardActions,
        ...layoutActions, ...checkoutActions, ...profileActions
    })(Component)
};

export default diagnosticConnect;