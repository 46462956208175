import { GET_LOGIN_DATA, GET_POCID, GET_FREQUENT_PURCHASES, GET_SEARCH_RESULT, GET_USER_METRICS, GET_LAST_PRESCRIPTION, GET_UPCOMING_CONSULTATION, GET_VIDEO_SESSION_DETAIL, GET_RATING_DETAILS, SET_SERVICE_CATEGORIES, GET_PHLEBO_ORDER_IN_PROGRESS, PRIVILEGE_PROFILE_DATA, PC_CARD_BENEFITS } from './dashboard.types';

export default {
    setGlobalSearchResult(item: any) {
        return (dispatch: any) => {
            dispatch({ type: GET_SEARCH_RESULT, payload: item });
        }
    },
    setMetricsData(item: any) {
        return (dispatch: any) => {
            dispatch({ type: GET_USER_METRICS, payload: item });
        }
    },
    setLoginData(item: any) {
        return (dispatch: any) => {
            dispatch({ type: GET_LOGIN_DATA, payload: item });
        }
    },
    setFreqPurchases(item: any) {
        return (dispatch: any) => {
            dispatch({ type: GET_FREQUENT_PURCHASES, payload: item });
        }
    },
    setPocId(item: any) {
        return (dispatch: any) => {
            dispatch({ type: GET_POCID, payload: item });
        }
    },
    setLastPrescription(item: any) {
        return (dispatch: any) => {
            dispatch({ type: GET_LAST_PRESCRIPTION, payload: item });
        }
    },
    setUpcomingConsultations(item: any) {
        return (dispatch: any) => {
            dispatch({ type: GET_UPCOMING_CONSULTATION, payload: item });
        }
    },
    setSessionDetail(item: any) {
        return (dispatch: any) => {
            dispatch({ type: GET_VIDEO_SESSION_DETAIL, payload: item });
        }
    },
    setRatingDetail(item: any) {
        return (dispatch: any) => {
            dispatch({ type: GET_RATING_DETAILS, payload: item });
        }
    },
    setServiceCategories(categories, serviceMap) {
        return (dispatch: any) => {
            dispatch({ type: SET_SERVICE_CATEGORIES, payload: { categories, serviceMap } });
        }
    },
    setPhleboOrderInProgress(data) {
        return (dispatch: any) => {
            dispatch({ type: GET_PHLEBO_ORDER_IN_PROGRESS, payload: data });
        }
    },
    setPrivilegeProData(list) {
        return (dispatch: any) => {
            dispatch({ type: PRIVILEGE_PROFILE_DATA, payload: list });
        }
    },
    setPCBenefitsData(list) {
        return (dispatch: any) => {
            dispatch({ type: PC_CARD_BENEFITS, payload: list });
        }
    }

}