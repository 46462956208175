import React, { Component } from 'react';
import connect from '../diagnostics/diagnostic.connect';
import { withTranslation } from '../../../i18n';
import ErrorBoundary from '../layout/widgets/errorboundary/errorboundary.component';
// import './vijaya.style.scss';
import { Accordion } from "react-bootstrap";
import { Player } from "video-react";
import { Modal } from "antd";
import RouterUtil from '../../base/utils/routerUtil';
import "video-react/dist/video-react.css";
import "antd/dist/antd.css";
class FAQS extends Component {
    state: any = {
        visible: false,
        visible1: false,
        packageIndex: 0,
        blog: 0,
        selectedPackage: []
    };
    // state: any = {

    // }
    constructor(props) {
        super(props);
        this.state.pacakageBanners = require('public/vdc/data/faqs.json');
        let query = RouterUtil.getQuery() || {};
        this.state.identifier = query.faqs;
        this.state.selectedPackage = this.state.pacakageBanners.filter((packageItem, urlname) => {
            console.log('checkprops', this.state.identifier);

            return (packageItem.urlname == this.state.identifier)
        });

    }
    player: any;
    showModal = () => {
        this.setState({
            visible: true
        });
    };
    showModal1 = () => {
        this.setState({
            visible1: true
        });
    };
    hideModal = () => {
        this.setState({
            visible: false
        });
    };
    hideModal1 = () => {
        this.setState({
            visible1: false
        });
    };
    pause = () => {
        this.player.pause();
    };
    pause1 = () => {
        this.player.pause();
    };
    getFaqsList() {
        let UIPackageList = [];
        let { selectedPackage } = this.state;
        // let faqsdes
        // selectedPackage.forEach(element => {
        //     faqsdes = element;
        // });
        let UIPackageItem =
            <div>
                <div className="text-center">
                    <div className="d-flex justify-content-center mt-">
                        <h2 className="center mt-4 mb-4 newd-border ">
                            <span>
                                Frequently Asked Questions (FAQs)
                            </span>

                        </h2>
                    </div>
                </div>
                <section id="content" className="mt-2 mb-3">
                    <div className="content-wrap p-0">
                        <div className="">

                            <div className="row first">
                                <div className="collapse-group col-md-12">
                                    {selectedPackage.map((listName, index) => (
                                        <div key={"benefits" + index}>
                                            <div className="controls">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="container">
                                                            <div className="row">
                                                                <h3 id="headingfaq" className="newd-border">
                                                                    <span>
                                                                        {listName.name}
                                                                    </span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion line-res">
                                                <Accordion className='accordioan_arrow'>
                                                    {listName.list.map((selectDes, index) => (
                                                        <div key={"benefits" + index}>
                                                            <Accordion.Item eventKey={index + 1} className="card how_txt">
                                                                <Accordion.Header className="accord">&nbsp;&nbsp; {selectDes.subname} </Accordion.Header>
                                                                <Accordion.Body>
                                                                    {(selectDes.des != undefined || selectDes.des != null) && (
                                                                        <p>
                                                                            <span className="accordian-list printcontent">
                                                                                <p>{selectDes.des}</p></span>
                                                                        </p>
                                                                    )}

                                                                    {
                                                                        (selectDes.video != undefined || selectDes.video != null) && (
                                                                            <div className='all_faqs'>
                                                                                <span className="video_image" onClick={this.showModal}>
                                                                                    <i className="fa fa-play play_btn" aria-hidden="true"></i> Play This
                                                                                    Video

                                                                                </span>
                                                                                <Modal
                                                                                    title="How to Prepare for Preventive Health Check-up | Vijaya Diagnostic Centre"
                                                                                    visible={this.state.visible}
                                                                                    footer={null}
                                                                                    onCancel={this.hideModal}
                                                                                    afterClose={this.pause}
                                                                                    bodyStyle={{ padding: 0 }}
                                                                                >

                                                                                    <Player autoPlay ref={(player) => { this.player = player }}>
                                                                                        <source src={selectDes.video} />
                                                                                    </Player>
                                                                                </Modal>

                                                                            </div>

                                                                        )}

                                                                    {(selectDes.sublist != undefined || selectDes.sublist != null) && (

                                                                        <div className='all_faqs'>
                                                                            {selectDes.sublist.map((selectList, index) => (
                                                                                <div key={"benefits" + index}>
                                                                                    <span className="accordian-list printcontent">
                                                                                        <p> <strong>{selectList.subname}</strong>
                                                                                            {(selectList.list != undefined || selectList.list != null) && (
                                                                                                <div >
                                                                                                    {selectList.list.map((listDel, index) => (
                                                                                                        <div key={"benefits" + index}>
                                                                                                            <ul className='generally_txt'>
                                                                                                                <li>{listDel.des}</li>
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div >
                                                                                            )}
                                                                                            <p>{selectList.des}</p>
                                                                                        </p>
                                                                                    </span>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}


                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </div>
                                                    ))}
                                                </Accordion>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        UIPackageList.push(UIPackageItem)
        if (UIPackageList.length > 0) {
            return (
                UIPackageList
            )
        }
    }
    render() {
        let { selectedPackage } = this.state;
        return (
            <ErrorBoundary>
                <div className="text-center">
                    <div className="d-flex justify-content-center mt-">
                        <h2 className="center mt-4 mb-4 newd-border ">
                            <span>
                                Frequently Asked Questions (FAQs)
                            </span>

                        </h2>
                    </div>
                </div>
                <section id="content" className="mt-2 mb-3">
                    <div className="content-wrap p-0">
                        <div className="">

                            <div className="row first">
                                <div className="collapse-group col-md-12">
                                    {selectedPackage.map((listName, index) => (
                                        <div key={"benefits" + index}>
                                            <div className="controls">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="container">
                                                            <div className="row">
                                                                <h3 id="headingfaq" className="newd-border">
                                                                    <span>
                                                                        {listName.name}
                                                                    </span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion line-res">
                                                <Accordion className='accordioan_arrow'>
                                                    {listName.list.map((selectDes, index) => (
                                                        <div key={"benefits" + index}>
                                                            <Accordion.Item eventKey={index + 1} className="card how_txt">
                                                                <Accordion.Header className="accord">&nbsp;&nbsp; {selectDes.subname} </Accordion.Header>
                                                                <Accordion.Body>
                                                                    {(selectDes.des != undefined || selectDes.des != null) && (
                                                                        <p>
                                                                            <span className="accordian-list printcontent" dangerouslySetInnerHTML={{ __html: selectDes?.des }}>
                                                                                </span>
                                                                        </p>
                                                                    )}

                                                                    {
                                                                        (selectDes.video != undefined || selectDes.video != null) && (
                                                                            <div className='all_faqs'>
                                                                                <span className="video_image" onClick={this.showModal}>
                                                                                    <i className="fa fa-play play_btn" aria-hidden="true"></i> Play This
                                                                                    Video

                                                                                </span>
                                                                                <Modal
                                                                                    title="How to Prepare for Preventive Health Check-up | Vijaya Diagnostic Centre"
                                                                                    visible={this.state.visible}
                                                                                    footer={null}
                                                                                    onCancel={this.hideModal}
                                                                                    afterClose={this.pause}
                                                                                    bodyStyle={{ padding: 0 }}
                                                                                >

                                                                                    <Player autoPlay ref={(player) => { this.player = player }}>
                                                                                        <source src={selectDes.video} />
                                                                                    </Player>
                                                                                </Modal>

                                                                            </div>

                                                                        )}

                                                                    {(selectDes.sublist != undefined || selectDes.sublist != null) && (

                                                                        <div className='all_faqs'>
                                                                            {selectDes.sublist.map((selectList, index) => (
                                                                                <div key={"benefits" + index}>
                                                                                    <span className="accordian-list printcontent">
                                                                                        <strong>{selectList.subname}</strong>
                                                                                        {(selectList.list != undefined || selectList.list != null) && (
                                                                                            <div >
                                                                                                {selectList.list.map((listDel, index) => (
                                                                                                    <div key={"benefits" + index}>
                                                                                                        <ul className='generally_txt'>
                                                                                                            <li dangerouslySetInnerHTML={{ __html: listDel?.des }}></li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div >
                                                                                        )}
                                                                                        <p>{selectList.des}</p>
                                                                                    </span>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}


                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </div>
                                                    ))}
                                                </Accordion>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ErrorBoundary>
        )
    }
}
export default connect(withTranslation(['common'])(FAQS));